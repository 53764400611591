












// It can also be imported as { GoogleLogin }

/* eslint-disable @typescript-eslint/camelcase */
export default {
  name: 'TextView',
  components: {},
  methods: {
    translate(name: string): string {
      return this.$vuetify.lang.t('$vuetify.Router.' + name);
    }
  }
};
